import React from "react";
import "./article.css";

const article = () => {
  return (
    <article className="artice">
      <p>Article</p>
    </article>
  );
};

export default article;
